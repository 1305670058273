@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

@layer components {
  .my-react-select-container .my-react-select__control {
    @apply bg-white w-full dark:bg-[rgb(55,65,81)] border-neutral-300 dark:border-none hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100 dark:bg-[rgb(55,65,81)] border-2 border-none;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-[rgb(55,65,81)] dark:hover:bg-[rgba(5,75,101)];
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__control .my-react-select__value-container .my-react-select__input-container .my-react-select__input {
    @apply shadow-none;
  }

  .my-react-select-container .my-react-select__control .my-react-select__value-container .my-react-select__multi-value {
    @apply dark:bg-[rgb(31,41,55)];
  }

  .my-react-select-container .my-react-select__control .my-react-select__value-container .my-react-select__multi-value .my-react-select__multi-value__label {
    @apply dark:text-white;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200 border-none;
  }
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}